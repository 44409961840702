body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
}

@font-face {
  font-family: 'monospaceRoboto';
  src: url('Roboto_Mono/RobotoMono-VariableFont_wght.ttf') format('truetype');
}



/* Add more @font-face rules for other weights/styles if needed */


* {
  margin: 0px;
  padding: 0px;
  font-family: monospaceRoboto;
}
