.experience-timeline {
    margin-top: 10vh
}

.experience-timeline::before {
   content: '';
   height: 100%;
   position: absolute;
   width: 4px;
   left: 50%;
   background-color: blueviolet;

}

.experience-connector {
    content: '';
    position: absolute;
    border-left: 50px solid blueviolet;
    border-top: 25px solid transparent;
    border-bottom: 25px solid transparent;
    width: 0;
    height: 0;
    left: calc(50%);
    top: calc(50% - 50px);
    opacity: 0.5;
}

.experience-connector-flipped {
    content: '';
    position: absolute;
    border-right: 50px solid blueviolet;
    border-top: 25px solid transparent;
    border-bottom: 25px solid transparent;
    width: 0;
    height: 0;
    left: calc(50% - 50px);
    top: calc(50% - 50px);
    opacity: 0.5;
}

.experience-unit {
    opacity: 0;
}

.experience-unit-flipped {
    opacity: 0;
}

.experience-unit-anim {
    animation: bounce 0.5s ease-out 0.7s forwards;
}

@keyframes bounce {
    0% {
        left: -100px;
        opacity: 0;
        filter: blur(5px);
    }

    60% {
        left: 20px;
        opacity: 1;
        filter: blur(2px)
    }

    100% {
        left: 0px;
        opacity: 1;
        filter: blur(0px)
    }
}

.experience-unit-flipped-anim {
    animation: bounce-flip 0.7s ease-out 0.7s forwards;
}

@keyframes bounce-flip {
    0% {
        left: calc(50% + 160px);
        opacity: 0;
        filter: blur(5px)
    }

    60% {
        left: calc(50% + 40px);
        opacity: 1;
        filter: blur(2px)
    }

    100% {
        left: calc(50% + 60px);
        opacity: 1;
        filter: blur(0px)
    }
}