@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);

.contact-form { width: min(80%, 1200px); margin:25px; }

::placeholder {
    color: gray
}

.feedback-input {
  color:#333333;
  font-weight:500;
  font-size: 18px;
  line-height: 22px;
  background-color: transparent;
  border-bottom:2px solid #dfdfdf;
  border-top: none;
  border-right: none;
  border-left: none;
  padding: 13px;
  margin-bottom: 15px;
  width:100%;
  box-sizing: border-box;
  outline:0;
}

.feedback-input-textarea {
  height: 150px;
  line-height: 150%;
  resize: none;
  border: 2px solid #dfdfdf;
  
}

[type="submit"] {
  min-width: fit-content;
  padding-left: 20px;
  padding-right: 20px;
  background:blueviolet;
  border-radius:5px;
  border:0;
  cursor:pointer;
  color:white;
  font-size:16px;
  padding-top:10px;
  padding-bottom:10px;
  transition: all 0.3s;
  margin-top:-4px;
  font-weight:700;
  border-radius: 300px;
  color: white
}

[type="submit"]:hover { background:black}