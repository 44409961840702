.project-container {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.project-container::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}
.skill {
    
    width: fit-content;
    min-width: 6%;
    box-shadow: 3px 3px 3px #999;
    background-color: #fff;
    border-radius: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: calc((2.5cqh + 2.5cqw) * 0.5);
    padding-right: calc((2.5cqh + 2.5cqw) * 0.5);
    font-size: calc(2cqw + 1cqh);
    color: black;
    font-weight: 500;
    margin-top: 5px;
    margin-left: 3px;
}