* {margin: 0; padding: 0;}
.tree ul {
	position: relative;
	font-size: 20px;
	transition: all 0.5s;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	justify-content: center;
	display: flex;
}

.tree li {
	float: left; text-align: center;
	list-style-type: none;
	position: relative;
	transition: all 0.5s;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
}

.legend {
	z-index: -1;
}
@media screen and (min-width: 801px) and (max-width: 1300px) {
	.tree li {
		font-size: 8px;
	}
	.legend {
		font-size: 16px
	}
	.box-width {
		width: 300px
	}
	.tree li a {
		font-weight: 500
	}
	.tree ul ul::before {
		height: 20px;
	}
	.tree > ul > li > ul::before {
		height: 20px;
	}
	.tree li::before, .tree li::after {
		height: 19px;
	}
	.tree ul {
		padding-top: 20px;
	}
	.tree li {
		padding: 20px 5px 0 5px;
	}
}
@media screen and (min-width: 1301px) and (max-width: 1860px) {
	.tree li {
		font-size: 11.5px
	}
	.legend {
		font-size: 24px
	}
	.box-width {
		width: 450px
	}
	.tree li a {
		font-weight: 500
	}
	.tree ul ul::before {
		height: 30px;
	}
	.tree > ul > li > ul::before {
		height: 30px;
	}
	.tree li::before, .tree li::after {
		height: 29px;
	}
	.tree ul {
		padding-top: 30px;
	}
	.tree li {
		padding: 30px 5px 0 5px;
	}

}
@media screen and (min-width: 1861px) {
	.tree li {
		font-size: 18px
	}
	.legend {
		font-size: 32px
	}
	.box-width {
		width: 600px
	}
	.tree li a {
		font-weight: bold
	}
	.tree ul ul::before {
		height: 50px;
	}
	.tree > ul > li > ul::before {
		height: 50px;
	}
	.tree li::before, .tree li::after {
		height: 49px;
	}
	.tree ul {
		padding-top: 50px;
	}
	.tree li {
		padding: 50px 5px 0 5px;
	}

}

.tree li a.header {
	font-size: 1.5em;
	padding-left: 16px;
	padding-right: 16px;
}

.tree li a.subheader {
	font-size: 1.5em;
	padding-left: 16px;
	padding-right: 16px;
}

/*We will use ::before and ::after to draw the connectors*/

.tree li::before, .tree li::after{
	content: '';
	position: absolute; top: 0; right: 50%;
	border-top: 3px solid black;
	width: 50%
}
.tree li::after{
	right: auto; left: 50%;
	border-left: 3px solid black;
}

/*We need to remove left-right connectors from elements without 
any siblings*/
.tree li:only-child::after, .tree li:only-child::before {
	display: none;
}

/*Remove space from the top of single children*/
.tree li:only-child{ padding-top: 0;}

/*Remove left connector from first child and 
right connector from last child*/
.tree li:first-child::before, .tree li:last-child::after{
	border: 0 none;
}
/*Adding back the vertical connector to the last nodes*/
.tree li:last-child::before{
	border-right: 3px solid black;
	border-radius: 0 5px 0 0;
	-webkit-border-radius: 0 5px 0 0;
	-moz-border-radius: 0 5px 0 0;
}
.tree li:first-child::after{
	border-radius: 5px 0 0 0;
	-webkit-border-radius: 5px 0 0 0;
	-moz-border-radius: 5px 0 0 0;
}

/*Time to add downward connectors from parents*/
.tree ul ul::before{
	content: '';
	position: absolute; top: 0; left: 50%;
	border-left: 3px solid black;
	width: 0;
}

.tree > ul > li > ul::before {
	content: '';
	position: absolute; top: 0px; left: 50%;
	border-left: 3px solid black;
	width: 0;
}

.tree > ul > li > a {
	margin-bottom: 0px;
}

.tree li a{
	border-width: 2px;
	border-color: black;
	padding: 15px 5px;
	border-style: solid;
	text-decoration: none;
	color: black;
	font-size: 1em;
	display: inline-block;
	user-select: none;
	
	border-radius: 5px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	
	transition: background-color 0.5s;
	-webkit-transition: background-color 0.5s;
	-moz-transition: background-color 0.5s;

}

.willtake.willtake {
	background-color: blueviolet;
	color: white;
}

/*Time for some hover effects*/
/*We will apply the hover effect the the lineage of the element also*/
.tree li a:hover, .tree li a:hover+ul li a {
	background: orange;
	color: black;
}
/*Connector styles on hoverf*/
.tree li a:hover+ul li::after, 
.tree li a:hover+ul li::before, 
.tree li a:hover+ul::before, 
.tree li a:hover+ul ul::before{
}

.ta {
	background-color: #00bc6d;
}

.tree *:not(.classText) {
  opacity: 0;
  filter: blur(5px);
  transform: translateY(10%);
  transition: opacity 1s, filter 1s, transform 1s, background-color 0.5s;
}

