
body {
  overflow-x: hidden;
}

.mobileMenu {
  transition: left 0.2s linear
}

.download-resume {
  font-size: 16px;
  font-family: someFont;
  margin-top: 20px;
  border-left: 2px solid black;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
  border-radius: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-decoration: none;
  background-color: orange;
}


a:visited {
  color: black
}

li {
  font-size: 28px;
  font-weight: 300;
  margin-bottom: 12px;
}

.uColor {
  border-bottom: 3px solid #fbfdfd;
}

.uColor-focused {
  transition: border-color 0.3s linear, border-bottom 0.3s linear;
  border-bottom: 3px solid purple;
}

.uColor:hover {
  transition: border-color 0.3s linear, border-bottom 0.3s linear;
  border-bottom: 3px solid purple
}

.uColorFont {
  color: black
}

.uColorFont:hover {
  transition: color 0.1s linear;
  color: purple
}

.menuSelections {
  opacity: 1
}

.menuSelections:hover {
  opacity: 0.5;
}

.hidden {
  opacity: 0;
  filter: blur(5px);
  transform: translateX(-10%);
  transition: opacity 1s, filter 1s, transform 1s;
}

.hidden-right {
  opacity: 0;
  filter: blur(5px);
  transform: translateX(10%);
  transition: opacity 1s, filter 1s, transform 1s;
}

.shown {
  transform: translateX(0%);
  opacity: 1;
  filter: blur(0);
}

#shown2 {
  transform: translateY(0%);
  opacity: 1;
  filter: blur(0);
}

html {
  background-color: #fbfdfd
}